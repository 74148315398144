/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap");
@import "ngx-toastr/toastr";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
html,

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
}

.mat-mdc-snack-bar-container {
  &.red-snackbar {
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    --mdc-snackbar-container-color: #E1000F;
  }
  &.green-snackbar {
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    --mdc-snackbar-container-color: #3cb043;
  }
}
input[type="checkbox"] {
  display: grid;
  place-content: center;
  appearance: none;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  transform: translateY(-0.075em);
  background-color: white;
  margin: 0;
}
input[type="checkbox"]:checked {
  background-color: #3cb043;
  border-color: #3cb043;
}
input[type="checkbox"]:indeterminate .indeterminateCheckbox {
  border-color: #3cb043;
  appearance: auto;
}

input[type="checkbox"].indeterminateCheckbox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border-color: #3cb043;
  background-color: #ecf7ec80;
}

input[type="checkbox"]:indeterminate::after {
  content: "";
  display: block;
  width: 12px;
  border: solid #3cb043;
  border-width: 3px 0 0 0;
  border-radius: 8px;
  position: absolute;
  top: 8px;
  left: 3px;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform-origin: center;
  transition: 120ms transform ease-in-out;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  background-color: green;
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
  background-color: white;
}

app-account-settings .mdc-tab--active {
  background-color: #ecf7ec;
}
app-settings .mdc-tab--active {
  background-color: #ecf7ec;
}
// input.ng-invalid:not(form) {
//   border: 1px solid red;
// }
app-account-settings .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #3cb043;
}
app-settings .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #3cb043;
}

app-product-configure .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #3cb043;
}

app-account-settings
  .mat-mdc-tab-link.mdc-tab--active:focus
  .mdc-tab__text-label {
  color: #3cb043;
}
app-settings .mat-mdc-tab-link.mdc-tab--active:focus .mdc-tab__text-label {
  color: #3cb043;
}

app-product-configure
  .mat-mdc-tab-link.mdc-tab--active:focus
  .mdc-tab__text-label {
  color: #3cb043;
}

app-account-settings .mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
  color: #3cb043;
}
app-settings .mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
  color: #3cb043;
}

app-product-configure .mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
  color: #3cb043;
}

app-account-settings .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #3cb043;
}
app-settings .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #3cb043;
}

app-product-configure .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #3cb043;
}

app-account-settings
  .mat-mdc-tab.mdc-tab--active:focus
  .mdc-tab-indicator__content--underline {
  border-color: #3cb043;
}
app-settings
  .mat-mdc-tab.mdc-tab--active:focus
  .mdc-tab-indicator__content--underline {
  border-color: #3cb043;
}

app-product-configure
  .mdc-tab-indicator__content
  .mdc-tab-indicator__content--underline {
  border-color: #3cb043;
}

app-account-settings .mat-mdc-tab-body {
  background-color: white;
}
app-settings .mat-mdc-tab-body {
  background-color: white;
}
app-settings .mat-mdc-tab-body {
  background-color: white;
}

app-account-settings .mat-mdc-tab-header {
  background-color: #fbfbfb;
}
app-settings .mat-mdc-tab-header {
  background-color: #fbfbfb;
}
app-settings .mat-mdc-tab-header {
  background-color: #fbfbfb;
}

app-product-configure .mat-mdc-tab-header {
  background-color: white;
}

app-product-configure .mat-mdc-tab-links {
  border-bottom: 1px solid #e8eaeb;
}
app-account-settings .mat-mdc-tab-links {
  border-bottom: 1px solid #e8eaeb;
}
app-settings .mat-mdc-tab-links {
  border-bottom: 1px solid #e8eaeb;
}

app-account-settings .mdc-tab--active {
  background-color: #ecf7ec;
  border-bottom: 2px solid #3cb043;
}
app-settings .mdc-tab--active {
  background-color: #ecf7ec;
  border-bottom: 2px solid #3cb043;
}

app-account-settings
  .mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs
  .mat-mdc-tab-link {
  flex-grow: 0;
}
app-settings
  .mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs
  .mat-mdc-tab-link {
  flex-grow: 0;
}

app-account-settings .mdc-tab-indicator {
  display: none;
}
app-settings .mdc-tab-indicator {
  display: none;
}

app-product-configure .mdc-tab-indicator {
  display: none;
}

app-payment-details .mat-mdc-tab:not(.mdc-tab--stacked) {
  height: 30px;
  border-radius: 8px;
}

app-payment-details .mat-mdc-tab-header {
  border-radius: 8px;
}

app-payment-details .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: white;
}

app-payment-details .mdc-tab-indicator__content--underline {
  border: none;
}
app-payment-details .mat-mdc-tab-body-content {
  overflow: hidden;
}

app-payment-details .mat-mdc-tab.mdc-tab--active:focus .mdc-tab__text-label {
  color: white;
}

app-payment-details .mdc-tab--active {
  background-color: #3cb043 !important;
  transition: all 1s;
}
app-payment-details .mdc-tab {
  padding-right: 0;
  padding-left: 0;
}

app-payment-details .mat-mdc-tab-label-container {
  padding: 8px;
  background-color: #f6f8f8;
  // width: 245px;
  width: 388px;
  border-radius: 8px;
  flex-grow: 0;
}
app-settings .mat-mdc-tab-label-container {
  width: 245px !important;
}
app-account-settings .mat-mdc-tab-label-container {
  width: 245px !important;
}
app-payment-details .mat-mdc-tab-labels {
  gap: 10px;
}

app-payment-details .mdc-tab {
  min-width: 81px !important;
}

app-payment-details .mat-mdc-tab-header {
  background-color: #fff;
}

app-payment-details .mat-mdc-tab-group {
  --mat-tab-header-active-focus-label-text-color: white;
  --mat-tab-header-active-hover-label-text-color: white;
}
app-settings .mat-mdc-tab-links {
  flex-direction: row;
}

app-settings .mdc-tab--active {
  background-color: #f7fcf7;
  transition: all 1s;
  border-radius: 0;
}

app-product-configure .mdc-tab--active {
  background-color: #ecf7ec;
  border-bottom: 2px solid #3cb043;
}

app-admin-dashboard {
  --mat-sidenav-content-background-color: white;
  --mat-sidenav-container-elevation-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
}

app-admin-dashboard .mat-mdc-tab-group {
  --mdc-tab-indicator-active-indicator-color: #3cb043;
  --mat-tab-header-active-focus-indicator-color: #3cb043;
  --mat-tab-header-active-hover-indicator-color: #3cb043;
  --mat-tab-header-inactive-ripple-color: none;
  --mat-tab-header-active-ripple-color: none;

}
app-admin-dashboard .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0);
}

app-notifications .mat-mdc-tab-header .mdc-tab {
  border-bottom: 1px solid #e3e3e380;
  padding-right: 8px;
  padding-left: 8px;
  padding-block: 8px;
}

// app-admin-dashboard .mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label {
//   color: red;
// }

app-notifications .mat-mdc-tab:not(.mdc-tab--stacked) {
  height: fit-content;
}

app-notifications .mat-mdc-tab:not(.mdc-tab--stacked):hover {
  background-color: white;

}

app-notifications .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #3cb043;
}

app-notifications .mat-mdc-tab .mdc-tab__text-label {
  color: #979797;
  font-size: 13px;
}

app-notifications .mat-mdc-tab.mdc-tab--active:focus .mdc-tab__text-label {
  color: #3cb043;
}

.custom-panel {
  width: fit-content;
}

.otp-input[_ngcontent-ng-c3470639301] {
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 2px solid #dddfe1 !important;
  outline: none;
}

.iti {
  width: 100%;
}
.iti__country-list {
  width: 400px;
  margin-top: 8px;
}

app-preference-selection .mat-expansion-indicator:after {
  border-color: black;
}

app-preference-selection {
  --mdc-circular-progress-active-indicator-color: #3cb043;
}
app-product-configure {
  --mdc-circular-progress-active-indicator-color: white;
}
app-image-slider {
  --mdc-circular-progress-active-indicator-color: #3cb043;
}

app-wishlist-button {
  --mdc-circular-progress-active-indicator-color: #f59e0b;
}

app-custom-select .mat-mdc-select-arrow {
  border-left: 15px solid transparent !important;
  border-right: none !important;
  border-top: 15px solid transparent !important;
  border-image-source: url("./assets/chevron-down.svg") !important;
  border-image-repeat: stretch !important;
}
app-custom-select .mat-mdc-select-arrow:focus {
  transform: rotate(180deg);
}
app-custom-select .mat-mdc-select-arrow svg {
  display: none;
}
app-custom-select .mat-mdc-select {
  display: flex;
}

app-custom-select .mat-mdc-select-required.mat-mdc-select-invalid {
  border-color: red;
}
app-custom-select .mat-mdc-select-arrow-wrapper {
  margin-right: 10px;
}

app-custom-select .mat-mdc-select-placeholder {
  margin-left: 10px;
  color: #b9bdc2;
}
app-orders .mat-mdc-select-placeholder {
  text-align: center;
  color: #1d2939;
  font-weight: 500;
  font-size: 14px;
}
app-orders .mat-mdc-form-field-infix {
  width: 170px;
}

app-custom-select .mat-mdc-option .mat-icon,
.mat-mdc-option .mat-pseudo-checkbox-full {
  width: 20px !important;
  height: 20px !important;
  margin-right: 0px !important;
  border-radius: 6px;
  border: 1.5px solid #d0d5dd;
}
app-custom-select .mat-mdc-option .mat-icon,
.mat-mdc-option .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
  background-color: #3cb043;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #3cb043 !important;
  font-size: 14px;
}

app-custom-select .mat-mdc-option .mat-icon,
.mat-mdc-option .mat-pseudo-checkbox-minimal {
  margin-left: 0px !important;
}
app-custom-select .mat-mdc-option .mat-icon,
.mat-mdc-option
  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  color: #3cb043 !important;
}

.myPanel {
  margin-block: 10px;
  border-radius: 4px !important;
}
app-custom-select .mat-mdc-select-value-text {
  margin-left: 10px;
}

app-custom-select
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mat-mdc-form-field-infix {
  padding: 0px !important;
}

app-custom-select .mat-mdc-form-field-infix {
  min-height: max-content !important;
}
app-custom-select .mat-mdc-form-field {
  display: inline !important;
  flex-direction: row !important;
  --mdc-outlined-text-field-outline-color: #e8eaeb;
  --mdc-outlined-text-field-disabled-outline-color: #e8eaeb;
  --mdc-outlined-text-field-hover-outline-color: #e8eaeb;
  --mdc-outlined-text-field-focus-outline-color: #3cb043;
}
app-custom-select .mdc-text-field--outlined {
  padding: 0px !important;
}

app-custom-select .mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
}

.myOption.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: #3cb043 !important;
}

app-category-management {
  padding-block: 4px !important;
}
.mat-mdc-menu-content {
  padding: 0px !important;
}

.cdk-overlay-dark-backdrop {
  backdrop-filter: blur(4px) !important;
  background: none;
  background-color: rgba(228, 228, 228, 0.1);
  box-shadow: 0px 10px 15px 10px rgba(0, 0, 0, 0.15);
}
app-header
  .mat-badge-small.mat-badge-overlap.mat-badge-after
  .mat-badge-content {
  right: 2px;
}

app-header .mat-badge-small.mat-badge-above .mat-badge-content {
  top: 4px;
}

.cookieShadow {
  box-shadow: 0px -1px 43px -12px rgba(107, 107, 107, 0.93);
  -webkit-box-shadow: 0px -1px 43px -12px rgba(107, 107, 107, 0.93);
  -moz-box-shadow: 0px -1px 43px -12px rgba(107, 107, 107, 0.93);
}
.customShadow {
  box-shadow: 0px 1px 4px 0px rgba(30, 41, 59, 0.25);
}

.myTooltipClass {
  text-decoration: underline;
  text-decoration-color: red;
  border-radius: 6px;
  width: max-content;
  box-shadow: 0px 1px 4px 0px rgba(30, 41, 59, 0.25);

  --mdc-plain-tooltip-supporting-text-line-height: 34px;
  --mdc-plain-tooltip-container-color: white;
  --mdc-plain-tooltip-supporting-text-color: red;
  --mdc-plain-tooltip-supporting-text-size: 14px;
  --mdc-plain-tooltip-supporting-text-weight: 500;
}
.amberToolTip {
  text-decoration: underline;
  text-decoration-color: #f2d260;
  box-shadow: 0px 1px 4px 0px rgba(30, 41, 59, 0.25);
  border-radius: 6px;

  --mdc-plain-tooltip-supporting-text-line-height: 34px;
  --mdc-plain-tooltip-container-color: white;
  --mdc-plain-tooltip-supporting-text-color: #f2d260;
  --mdc-plain-tooltip-supporting-text-size: 14px;
  --mdc-plain-tooltip-supporting-text-weight: 500;
}

.myScroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  margin-inline: 2px; /* width of the entire scrollbar */
}

.myScroll::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.toast-success {
  background-image: url("./assets/toast-success.svg");
  background-color: #19af66;
  background-repeat: no-repeat;
}
.toast-error {
  background-image: url("./assets/toast-error.svg");
  background-color: #f35746;
  background-repeat: no-repeat;
}

.toast-title {
  font-weight: 600;
  font-size: 18px;
  font-family: "Inter";
}
.toast-message {
  word-wrap: break-word;
  font-size: 16px;
  font-family: "Inter";
}
.toast-container .ngx-toastr {
  box-shadow: 0px 0px 2px 0px #00000026;
  border-radius: 6px;
}
.toast-container .ngx-toastr:hover {
  box-shadow: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

app-date-picker .mat-date-range-input-container {
  display: none;
}

app-date-picker .mat-date-range-input {
  width: 0;
}

app-date-picker .mat-mdc-button-persistent-ripple {
  display: none;
}

.address-dropdown-item {
  padding-inline: 16px;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.address-dropdown-item:hover {
  background-color: #f5f5f5;
}

.address-text {
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  app-payment-details .mat-mdc-tab-group {
    max-width: 100%;
  }
}

@media only screen and (min-width:768px) {
  body {
    font-family: "Roboto", sans-serif;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.overflow-hidden {
  overflow: hidden;
}

.navbar-blur-background {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 1;
  backdrop-filter: blur(4px); /* For Safari */
  -webkit-backdrop-filter: blur(4px);
  background-color: rgba(228, 228, 228, 0);
}
